import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Link, ListItem } from "@mui/material";
import SocialLink from "components/SocialLink";
import { useTranslation as useLocale } from "react-i18next";

import "./Footer.scss";
import { useQueryFooterSections } from "shared/services/queries";

const Footer = () => {
  const { t } = useLocale();
  const { lang } = useParams();
  const { data: footerData, isError } = useQueryFooterSections(
    lang === "ar-ps"
  );

  const LINKS = isError
    ? [
        {
          title: t("whoWeAre"),
          children: [
            {
              title: t("about-byd"),
              link: `${lang}/about-byd`,
            },
            {
              title: t("news"),
              link: `${lang}/news-list`,
            },
          ],
        },
        {
          title: t("car"),
          children: (window.pageData && window.pageData.models) || [],
        },
        {
          title: t("companyName"),
          children: [
            {
              title: t("emailAddress"),
              link: `mailto:${t("emailAddress")}`,
              type: `email`,
            },
            {
              title: t("phone"),
              link: `tel:${t("phone")}`,
            },
            {
              title: t("address"),
              link: `http://maps.google.com/?q=${t("address")}`,
            },
          ],
        },
      ]
    : footerData;

  return (
    <footer className="c-footer">
      <div className="c-footer_links">
        <div className="row d-block d-md-flex">
          <div className="col-12 mt-3">
            <ul className="footer-menu d-flex text-center justify-content-around flex-column flex-md-row">
              {LINKS?.map(
                ({ title, children, isSocial, socialList }, index) => (
                  <ListItem
                    key={`${title}-${index}`}
                    className="w-auto mx-2 justify-content-start d-flex flex-column"
                  >
                    {isSocial ? (
                      <div className="col-12 col-md-4 d-flex align-items-center social-links-footer w-100">
                        <SocialLink data={socialList} />
                      </div>
                    ) : (
                      <div className="text-center">
                        <p
                          color="inherit"
                          rel={title}
                          style={{ fontWeight: "bold" }}
                        >
                          {title}
                        </p>
                        <ul className="px-0">
                          {children?.map((child, index) => (
                            <ListItem
                              className="footer-item w-auto"
                              key={`${
                                child.text || child.title || child.tabTitle
                              }-${index}`}
                            >
                              <Link
                                className={
                                  child.type &&
                                  child.type === "email" &&
                                  "text-lowercase"
                                }
                                color="inherit"
                                href={child.link}
                                rel={
                                  child.text || child.title || child.tabTitle
                                }
                              >
                                {child.text || child.title || child.tabTitle}
                              </Link>
                            </ListItem>
                          ))}
                        </ul>
                      </div>
                    )}
                  </ListItem>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-separator"></div>
      <div className="c-footer_links">
        <div className="text-center copyright">
          ©️ {new Date().getFullYear()} BYD Auto Industry Co., Ltd. All rights
          reserved.
        </div>
      </div>
    </footer>
  );
};
export default Footer;
