import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { normalizeContent } from "shared/helpers/utils";

axios.interceptors.request.use(function (config) {
  config.headers["ngrok-skip-browser-warning"] = "69420";

  return config;
});

export const useQueryTwillPages = () =>
  useQuery({
    queryKey: ["all pages"],
    queryFn: async () => {
      const response = await axios.get("/api/allpages");

      const twillPages = response.data.map((item) => {
        item.slug = item.slugs.find((slug) => slug.active).slug;
        return item;
      });
      return twillPages;
    },
    onError: (error) => {
      console.error("Error fetching Twill pages:", error);
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });

export const useQueryFooterSections = (isArabic) =>
  useQuery({
    queryKey: ["footer"],
    queryFn: async () => {
      const response = await axios.get("/api/footer-info");

      const footerSections = response.data;
      return normalizeContent(footerSections, isArabic);
    },
    onError: (error) => {
      console.error("Error fetching footer info", error);
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });

export const useQueryHeadersLinks = (isArabic) =>
  useQuery({
    queryKey: ["header"],
    queryFn: async () => {
      const response = await axios.get("/api/header-links-custom");

      const headerLinks = response.data;
      return normalizeContent(headerLinks, isArabic);
    },
    onError: (error) => {
      console.error("Error fetching Twill pages:", error);
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });

export const useQueryLogoData = () =>
  useQuery({
    queryKey: ["logo"],
    queryFn: async () => {
      const response = await axios.get("/api/logo-data");

      const logoData = response.data;
      return logoData;
    },
    onError: (error) => {
      console.error("Error fetching Twill pages:", error);
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
