import React, { useState } from "react";
import Carousel from "components/Carousel/Carousel";
import CustomTabs from "components/CustomTabs/CustomTabs";
import CustomReactPlayer from "components/CustomReactPlayer/CustomReactPlayer";
import useTranslation from "shared/hooks/useTranslation";

import "./CarouselTabs.scss";
import Image from "../Image/Image";

const CarouselTabs = ({ list }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabCarouselChanged = (currentIndex) => {
    setTabIndex(currentIndex);
  };
  return (
    <div className="c-carousel-video">
      <div className="w-100">
        <Carousel
          arrows={false}
          dots={false}
          currentIndex={tabIndex}
          handleOnChange={handleTabCarouselChanged}
          childrenList={list.map(({ description, video, img }, index) => (
            <div
              className="slick-slider_content"
              key={index}
            >
              <div className="row custom-row">
                <div className="col-12">
                  {video ?
                    <CustomReactPlayer
                      url={video}
                      playing
                      loop
                      controls={false}
                      playsinline={true}
                      muted
                      width="100%"
                      height="100%"
                    />
                    :
                    <Image src={img} alt="Img Tab" />
                  }
                </div>
                <div className="col-12">
                  <div className="p-2 my-4 w-100 px-5 text-center">
                    {useTranslation(description)}
                  </div>
                </div>
              </div>
            </div>
          ))}
        />
        <div className="mb-5 mx-0 mx-md-5">
          <CustomTabs
            list={list}
            currentIndex={tabIndex}
            handleOnChange={handleTabCarouselChanged}
          />
        </div>
      </div>
    </div>
  );
};

export default CarouselTabs;
