import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as ScrollIcon } from "assets/images/scroll.svg";
import { Close as CloseIcon } from "@mui/icons-material";
import "./Sidebar.scss";

const Sidebar = ({ sections }) => {
  const [activeSection, setActiveSection] = useState("");
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const { lang } = useParams();
  useEffect(() => {
    const handleScroll = () => {
      let current = "";
      sections.forEach((section) => {
        const element = document.getElementById(section.id);
        const rect = element.getBoundingClientRect();
        if (rect.top <= window.innerHeight / 2 && rect.bottom >= 0) {
          current = section.id;
        }
      });
      setActiveSection(current);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sections]);

  const handleClick = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      className={`sidebar ${isSidebarVisible ? "visible" : "hidden"}`}
      style={{
        left: lang === "en-ps" ? "30px" : "auto",
        right: lang === "ar-ps" ? "30px" : "auto",
      }}
    >
      <button
        className={`toggle-button ${isSidebarVisible ? "close" : "open"}`}
        onClick={() => setIsSidebarVisible(!isSidebarVisible)}
      >
        {isSidebarVisible ? <CloseIcon /> : <ScrollIcon className="icon" />}
      </button>
      {isSidebarVisible && (
        <ul>
          {sections
            .filter((section) => section.title)
            .map((section) => (
              <li key={section.id}>
                <a
                  href={`#${section.id}`}
                  className={activeSection === section.id ? "active" : ""}
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick(section.id);
                    setIsSidebarVisible(false); // Hide sidebar after clicking a link on mobile
                  }}
                >
                  {section.title}
                </a>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default Sidebar;
