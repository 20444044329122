export const ar = {
  translation: {
    models: "الموديلات",
    "about-byd": "من نحن",
    "contact-us": "تواصل معنا",
    news: "الاخبار",
    service: "صيانة",
    "follow-us": "متابعه",
    "contact-us-title": "زودنا بمعلوماتك ليتم التواصل معك :",
    "contact-us-description":
      "يرجى ملاحظة أن كافة الحقول التي تحمل علامة (*) مطلوبة.",
    send: "ارسل",
    "first-name": "الاسم الأول*",
    "last-name": "الاسم الأخير*",
    "phone-number": "رقم الهاتف*",
    email: "البريد الإلكتروني*",
    city: "المدينة*",
    note: "الملاحظة",
    privacy:
      "فيما يتعلق بمعالجة BYD للبيانات الشخصية، يمكنك الرجوع إلى سياسة الخصوصية لمزيد من التفاصيل.",
    newsletter: "اخر العروض والأخبار",
    learnMore: "استكشاف المزيد",
    carModel: "نوع السيارة*",
    whoWeAre: "من نحن",
    car: "الموديلات",
    companyName: "أي موبليتي لتجارة المركبات",
    emailAddress: "info@byd.ps",
    phone: "17001771777",
    address: "دالاس سنتر - البالوع - البيرة",
  },
};
