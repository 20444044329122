import React from "react";
import { useParams } from "react-router-dom";

const useTranslation = (title) => {
  const { lang } = useParams();

  if (lang && title && typeof title === "object") {
    return title[lang.split("-")[0] || "en"];
  } else {
    return title;
  }
};
export default useTranslation;
