import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { cloneDeep } from "lodash";
import CoverImg from "components/CoverImg/CoverImg";
import LinkImg from "components/LinkImg/LinkImg";
import AddressInfo from "components/AddressInfo/AddressInfo";
import ImgItemList from "components/ImgItemList/ImgItemList";
import ItemList from "components/ItemList/ItemList";
import CarouselTabs from "components/CarouselTabs/CarouselTabs";
import Models from "components/Models/Models";
import DataList from "components/DataList/DataList";
import Video from "components/Video/Video";
import Form from "components/Form/Form";
import TextWidget from "components/Text/Text";

import "./Widget.scss";
import Grid from "../Grid/Grid";
import BasicTable from "components/Table/Table";

const Widget = ({ type, link, href, list, closeMenu, ...rest }) => {
  const navLink = link || href;

  const { lang } = useParams();
  const [newLink, setNewLink] = useState(navLink);
  const [newList, setNewList] = useState(list);
  useEffect(() => {
    if (navLink?.indexOf("/") === 0) {
      setNewLink(`/${lang}${navLink}`);
    }
    if (list?.length) {
      const listClone = cloneDeep(list).map((el) => {
        if (el?.link?.indexOf("/") === 0 || el?.href?.indexOf("/") === 0) {
          el.link = `/${lang}${el.link || el.href}`;
        }
        return el;
      });
      setNewList(listClone);
    }
  }, [list, navLink]);

  const renderComponent = () => {
    switch (type) {
      case "image":
        return <CoverImg link={newLink} list={newList} {...rest} />;
      case "nav_img":
        return <LinkImg link={newLink} {...rest} />;
      case "address":
        return <AddressInfo link={newLink} {...rest} />;
      case "image-with-horizantal-blocks":
        return <ImgItemList list={newList} {...rest} />;
      case "image-with-vertical":
        return <ItemList list={newList} {...rest} />;
      case "carousel-video":
        return <CarouselTabs list={newList} {...rest} />;
      case "models":
        return <Models list={newList} closeMenu={closeMenu} {...rest} />;
      case "data-list":
        return <DataList list={newList} {...rest} />;
      case "video":
        return <Video link={newLink} {...rest} />;
      case "form":
        return <Form {...rest} />;
      case "grid":
        return <Grid list={newList} {...rest} />;
      case "text":
        return <TextWidget title={rest.title} text={rest.text} />;
      case "table_section":
        return <BasicTable list={list} {...rest} />;
      default:
        return <p style={{ display: "none" }}>Widget not supported</p>;
    }
  };

  return <>{renderComponent()}</>;
};

export default Widget;
