// useScreenSize.js
import React, { useState, useEffect } from 'react';

const getDeviceInfo = () => {
  return {
    isMobile: window.innerWidth < 768,
    platform: window.innerWidth < 768 ? 'mobile' : 'desktop'
  };
}

const useDeviceDetect = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [deviceInfo, setDeviceInfo] = useState(getDeviceInfo());

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      setDeviceInfo(getDeviceInfo());
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { screenSize, ...deviceInfo };
};

export default useDeviceDetect;
