import React from "react";
import { Link } from "@mui/material";
import "./AddressInfo.scss";
import useTranslation from "shared/hooks/useTranslation";

const AddressInfo = ({ title, href, linkTitle }) => {
  return (
    <div className="c-address">
      <h6 className="c-title">{useTranslation(title)}</h6>
      {href ? (
        <Link className="c-info c-link" color="inherit" href={href}>
          {useTranslation(linkTitle)}
        </Link>
      ) : (
        <p className="c-info">{useTranslation(linkTitle)}</p>
      )}
    </div>
  );
};

export default AddressInfo;
