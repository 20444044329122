export const en = {
  translation: {
    models: "Models",
    "about-byd": "about BYD",
    "contact-us": "CONTACT",
    news: "news",
    service: "SERVICE ",
    "follow-us": "FOLLOW US",
    "contact-us-title": "Contact us for more information",
    "contact-us-description":
      "Please note that all fields marked with a(*) are required.",
    send: "Send",
    "first-name": "First Name*",
    "last-name": "Last Name*",
    "phone-number": "Phone Number*",
    email: "Email*",
    city: "City*",
    note: "Note",
    privacy:
      "Regarding BYD's processing of personal data, you can consult the privacy policy for more details.",
    newsletter: "Offers and news",
    learnMore: "Learn more",
    carModel: "Car Model*",
    whoWeAre: "Who We Are",
    car: "Car",
    companyName: "e Mobility  co.",
    emailAddress: "info@byd.ps",
    phone: "17001771777",
    address: "Dallas center - Albalu' - Albireh",
  },
};
