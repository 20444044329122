import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import './Carousel.scss';

const settings = {
  dots: false,
  infinite: true,
  autoplay: false,
  autoplaySpeed: 5000,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  pauseOnFocus: false,
  pauseOnHover: false,
  draggable: true,
  waitForAnimate: false,
  fade: false,
};

const Carousel = ({
  childrenList,
  currentIndex,
  handleOnChange,
  ...rest
}) => {
  const sliderRef = useRef(null);

  settings.beforeChange = (current, next) => {
    if (handleOnChange) {
      handleOnChange(next);
    }
  };

  useEffect(() => {
    goToSlide(currentIndex);
  }, [currentIndex]);

  const goToSlide = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  return (
    <div>
        <Slider
          ref={sliderRef}
          {...settings}
          {...rest}
        >
            {childrenList.map((children,index) => (
                <div key={index}>
                  {children}
                </div>
            ))}
        </Slider>
    </div>
  );
};

export default Carousel;
