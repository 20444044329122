import React, { useEffect } from "react";
import { Tab, Tabs } from "@mui/material";
import { Link } from "@mui/material";
import useTranslation from "shared/hooks/useTranslation";
import Image from "components/Image/Image";
import { useParams } from "react-router-dom";

import "./CustomTabs.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`custom-tabpanel-${index}`}
      aria-labelledby={`custom-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `custom-tab-${index}`,
    "aria-controls": `custom-tabpanel-${index}`,
  };
}

export default function CustomTabs({ list, currentIndex, handleOnChange }) {
  const [value, setValue] = React.useState(currentIndex || 0);

  useEffect(() => {
    setValue(currentIndex);
  }, [currentIndex]);

  const handleChange = (newValue) => {
    setValue(newValue);
    if (handleOnChange) {
      handleOnChange(newValue);
    }
  };

  const { lang } = useParams();

  return (
    <div className={`custom-tabs ${lang === "ar-ps" ? "lang-ar" : ""}`}>
      <Tabs
        value={value}
        onChange={(e, newValue) => handleChange(newValue)}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        {list.map((item, index) => (
          <Tab
            key={index}
            label={useTranslation(item.title || item.tabTitle)}
            {...a11yProps(index)}
            className="p6"
          />
        ))}
      </Tabs>

      {list.map((item, index) => (
        <TabPanel
          key={index}
          value={value}
          index={index}
          className="u-color-grey-3"
        >
          {item.img && (
            <div className="panel-content">
              {item.title && (
                <div className="text-center">
                  <h4>{useTranslation(item.title)}</h4>
                </div>
              )}
              {item.description && (
                <div className="py-4 text-center">
                  <p
                    className="c-description"
                    dangerouslySetInnerHTML={{
                      __html: useTranslation(item.description),
                    }}
                  ></p>
                </div>
              )}
              <Link href={item.link}>
                <Image src={item.img} alt={item.title} />
                <p className="c-description text-center">
                  {useTranslation(item.imageDescription)}
                </p>
              </Link>
            </div>
          )}
        </TabPanel>
      ))}
    </div>
  );
}
