import React from "react";
import Widget from "components/Widget/Widget";
import { useParams } from "react-router-dom";
import SocialLink from "../../components/SocialLink";
import { ReactComponent as Twitter } from "assets/images/twitter.svg";
import { ReactComponent as Facebook } from "assets/images/facebook.svg";
import useTranslation from "shared/hooks/useTranslation";
import Sidebar from "components/SideBar/SideBar";

import "./CustomPage.scss";

const DATA = window?.pageData?.blocks || [];

// const DATA = [
//   {
//     type: "image",
//     img: "https://www.byd.com/content/dam/byd-site/en-jo/about-byd/semiconductor-chip-PC.jpg",
//     title: "Semiconductor Chip",
//     description:
//       "Semiconductor chip, the “CPU” of new energy vehicles, is the core technology of the whole industry.",
//   },
//   {
//     type: "image",
//     img: "https://www.byd.com/content/dam/byd-site/en-jo/about-byd/dilink-PC.jpg",
//     title: "BYD intelligent cockpit system",
//     description:
//       "All smartphone functions are integrated into the in-vehicle platform.",
//   },
//   {
//     type: "image",
//     img: "https://www.byd.com/content/dam/byd-site/en-jo/about-byd/vehicle-safety-PC.jpg",
//     title: "Vehicle Safety",
//     description: "Numerous challenging tests for top quality",
//   },
//   {
//     type: "table_section",
//     columns: "x,y,z",
//     images: [],
//     list: [
//       {
//         type: "table_row",
//         images: [],
//         list: [
//           {
//             type: "table_cell",
//             value: "a",
//             images: [],
//           },
//           {
//             type: "table_cell",
//             value: "vb",
//             images: [],
//           },
//           {
//             type: "table_cell",
//             value: "b",
//             images: [],
//           },
//         ],
//       },
//       {
//         type: "table_row",
//         images: [],
//         list: [
//           {
//             type: "table_cell",
//             value: "ccc",
//             images: [],
//           },
//           {
//             type: "table_cell",
//             value: "cc",
//             images: [],
//           },
//           {
//             type: "table_cell",
//             value: "ccc",
//             images: [],
//           },
//         ],
//       },
//       {
//         type: "table_row",
//         images: [],
//         list: [
//           {
//             type: "table_cell",
//             value: "aa",
//             images: [],
//           },
//           {
//             type: "table_cell",
//             value: "aa",
//             images: [],
//           },
//           {
//             type: "table_cell",
//             value: "aa",
//             images: [],
//           },
//         ],
//       },
//     ],
//   },
//   //   {
//   //     type: "image",
//   //     img: "https://www.byd.com/content/dam/byd-site/en-jo/about-byd/market-performance-PC.jpg",
//   //     title: "BYD YUAN PLUS EV debuts in Colombia",
//   //     description:
//   //       "<b>Bogotá, Colombia -</b> BYD, the world's leading manufacturer of new energy vehicles and electric batteries, recently launched its YUAN PLUS EV in Colombia. The milestone comes after BYD's new model entered Costa Rica, Uruguay and the Dominican Republic, which constitutes another step in BYD's expansion in Latin America.",
//   //     date: "2022.10.20",
//   //   },
//   //   {
//   //     type: "image",
//   //     img: "https://www.byd.com/content/dam/byd-site/en-jo/about-byd/market-performance-PC.jpg",
//   //     description:
//   //       'The BYD YUAN PLUS EV is the first A-class model built on BYD\'s e-Platform 3.0, with exceptional performance in terms of intelligence, efficiency, safety and aesthetics. This model embodies the captivating Dragon Face 3.0 design language, designed by Wolfgang Egger, BYD Global Design Director, and has a sporty interior, which responds to the demands of the SUV segment in the Colombian market. Its superior aerodynamic design reduces the drag coefficient to just 0.29 Cd, and it can accelerate from 0 to 100 km/h in just 7.3 seconds. Furthermore, thanks to its all-electric range of 480 km NEDC, the vehicle can offer customers a more convenient and comfortable urban commuting experience.<br> <br> The launch event of the YUAN PLUS EV was held within the framework of the celebration of the 10th anniversary of the prestigious local magazine "Hola Colombia", and was attended by numerous Colombian celebrities and well-known fashion figures. At the event, the YUAN PLUS EV was presented decorated with red roses in bloom, drawing the attention of attendees for the passion and energy it represented.',
//   //     title: "BYD YUAN PLUS EV",
//   //   },
//   //   {
//   //     type: "image-with-horizantal-blocks",
//   //     img: {
//   //       desktop:
//   //         "https://www.byd.com/content/dam/byd-site/en-jo/about-byd/diversification-PC.jpg",
//   //       mobile:
//   //         "https://www.byd.com/content/dam/byd-site/en-jo/about-byd/diversification-MOB.jpg",
//   //     },
//   //     title: "Diversification",
//   //     list: [
//   //       {
//   //         icon: "https://www.byd.com/content/dam/byd-site/en-jo/about-byd/icon1pc.png",
//   //         title: "Auto",
//   //         img: "https://www.byd.com/content/dam/byd-site/en-jo/about-byd/diversification-auto.jpg",
//   //       },
//   //       {
//   //         icon: "https://www.byd.com/content/dam/byd-site/en-jo/about-byd/icon2pc.png",
//   //         title: "Rail Transit",
//   //         img: "https://www.byd.com/content/dam/byd-site/en-jo/about-byd/diversification-rail-transit.jpg",
//   //       },
//   //       {
//   //         icon: "https://www.byd.com/content/dam/byd-site/en-jo/about-byd/icon3pc.png",
//   //         title: "Renewable Energy",
//   //         img: "https://www.byd.com/content/dam/byd-site/en-jo/about-byd/diversification-renewable-energy.jpg",
//   //       },
//   //       {
//   //         icon: "https://www.byd.com/content/dam/byd-site/en-jo/about-byd/icon4pc.png",
//   //         title: "Electronics",
//   //         img: "https://www.byd.com/content/dam/byd-site/en-jo/about-byd/diversification-electronics.jpg",
//   //       },
//   //     ],
//   //   },
// ];

export default function CustomPage() {
  const sections = DATA.map((item, index) => ({
    id: `section-${index + 1}`,
    title: useTranslation(item.title),
  }));
  return (
    <div className="c-page c-about d-flex min-vh-100 flex-column align-items-center justify-content-between">
      <Sidebar sections={sections} />
      {DATA.map((el, index) => (
        <div id={`section-${index + 1}`} key={el.link || index}>
          <Widget key={el.link || index} {...el} index={index} />
        </div>
      ))}
    </div>
  );
}
