import React from "react";
import useTranslation from "shared/hooks/useTranslation";
import Image from "components/Image/Image";

import "./ItemList.scss";
import { getDirectionLayoutClassName } from "shared/helpers/utils";

const ItemList = ({
  img,
  title,
  description,
  list,
  className,
  direction = "column",
}) => {
  const directionClass = getDirectionLayoutClassName(direction);
  return (
    <div className="c-image-with-vertical relative">
      <div className={`c-list ${directionClass ? directionClass : ""}`}>
          {(title || description) && (
              <div className="c-title">
                  <h2>{useTranslation(title)}</h2>
                  {description && (
                      <p
                          className="c-description"
                          dangerouslySetInnerHTML={{
                              __html: useTranslation(description),
                          }}
                      >
                          {useTranslation(description)}
                      </p>
                  )}
              </div>
          )}

          {list.map((item, index) => (
          <div className="c-item" key={index}>
            <h5 className="c-title">{useTranslation(item.title)}</h5>
            <div
              className="c-description"
              dangerouslySetInnerHTML={{
                __html: useTranslation(item.description),
              }}
            ></div>
          </div>
        ))}
      </div>
      <div className="c-image">
        <Image src={img} alt={title} />
      </div>
    </div>
  );
};

export default ItemList;
