import React from "react";
import classNames from "classnames";
import useTranslation from "shared/hooks/useTranslation";
import Image from "components/Image/Image";

import "./Grid.scss";

const Grid = ({ list, className }) => {
    return (
        <div className={classNames("c-grid row")}>
            {list?.map(({img, title, description, color})=> (
                <div className="col-12 col-md-4 text-center">
                    {img && (
                        <div className="c-image">
                            <Image src={img} alt={title} />
                        </div>
                    )}
                    {(useTranslation(title) || useTranslation(description)) && (
                        <div className={classNames("c-title text-center", className)}>
                            <h2 style={{ color: color || 'inherit'}}>{useTranslation(title)}</h2>
                            {description && (
                                <p style={{ color: color || 'inherit'}} className={classNames("c-description")}
                                   dangerouslySetInnerHTML={{
                                       __html: useTranslation(description),
                                   }}
                                />
                            )}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Grid;
