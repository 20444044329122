import { CircularProgress } from '@mui/material';
import "./Loading.scss";

const Loading = () => {
    return (
        <div className="Loading">
            <CircularProgress />
        </div>
    );
};
export default Loading;
