import React from "react";
import classNames from "classnames";
import { Route } from "react-router-dom";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

import "./PublicLayout.scss";

const PublicLayout = ({
  component: Component,
  className = "not-logged-in",
  ...rest
}) => {
  const lang = rest.computedMatch.params.lang;

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <div
          className={classNames("c-layout", className, lang)}
          dir={lang === "ar-ps" ? "rtl" : "ltr"}
        >
          <div className="d-flex items-end justify-conten-between">
            <Header />
          </div>
          <main className="c-public-layout c-layout_main background-style">
            <Component {...rest} {...matchProps} />
          </main>
          <Footer />
        </div>
      )}
    />
  );
};

export default PublicLayout;
