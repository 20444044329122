import React from "react";
import ReactPlayer from 'react-player';

const CustomReactPlayer = (props) => {

    return (
        <ReactPlayer {...props} />
  );
};

export default CustomReactPlayer;
