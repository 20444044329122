import React from "react";
import useTranslation from "shared/hooks/useTranslation";
import Image from "components/Image/Image";

import "./ImgItemList.scss";

const ImgItemList = ({ img, title, description, list }) => {
  return (
    <div className="c-image-with-horizantal-blocks relative">
      <div className="c-content">
        {(title || description) && (
          <div className="c-title">
            <h2>{useTranslation(title)}</h2>
            {description && (
              <p className="c-description">{useTranslation(description)}</p>
            )}
          </div>
        )}
        <div className="c-list row">
          {list.map((item) => (
            <div className="col-6 col-md-3 c-item_container" key={item.img}>
              <div className="c-item">
                  <div className="item-icon">
                    <Image src={item?.images?.icon} alt={item.title} />
                  </div>
                  <div className="item-title">{useTranslation(item.title)}</div>
                  <div className="item-img">
                    <Image src={item?.images?.img} alt={item.title} />
                  </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="c-image">
        <Image src={img} alt={title} />
      </div>
    </div>
  );
};

export default ImgItemList;
