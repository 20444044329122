import { ar } from "locales/translations/ar";
import { en } from "locales/translations/en";

export const formatNumberWithCommas = (number) => {
  if (number) {
    number = number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  }
  return number;
};

export const SmoothScrolling = {
  timer: null,

  stop: function () {
    clearTimeout(this.timer);
  },

  scrollTo: function (id, callback) {
    const settings = {
      duration: 100,
      easing: {
        outQuint: function (x, t, b, c, d) {
          return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
        },
      },
    };
    var percentage;
    var startTime;
    var node = document.getElementById(id);
    var nodeTop = node.offsetTop;
    var nodeHeight = node.offsetHeight;
    var body = document.body;
    var html = document.documentElement;
    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    var windowHeight = window.innerHeight;
    var offset = window.pageYOffset;
    var delta = nodeTop - offset;
    var bottomScrollableY = height - windowHeight;
    var targetY =
      bottomScrollableY < delta
        ? bottomScrollableY - (height - nodeTop - nodeHeight + offset)
        : delta;

    startTime = Date.now();
    percentage = 0;

    if (this.timer) {
      clearInterval(this.timer);
    }

    function step() {
      var yScroll;
      var elapsed = Date.now() - startTime;

      if (elapsed > settings.duration) {
        clearTimeout(this.timer);
      }

      percentage = elapsed / settings.duration;

      if (percentage > 1) {
        clearTimeout(this.timer);

        if (callback) {
          callback();
        }
      } else {
        yScroll = settings.easing.outQuint(
          0,
          elapsed,
          offset,
          targetY,
          settings.duration
        );
        window.scrollTo(0, yScroll);
        this.timer = setTimeout(step, 10);
      }
    }

    this.timer = setTimeout(step, 10);
  },
};

export const parseInfo = (data) => {
  let parsedData = "";
  if (data) {
    parsedData = JSON.stringify(data)
      .replaceAll('","', "<br>")
      .replaceAll('":"', ":&nbsp;")
      .replaceAll(":", ":&nbsp;")
      .replaceAll('"', "")
      .replaceAll("\\", "")
      .replaceAll("assistYou", "Case Type")
      .replaceAll("name", "Name")
      .replaceAll("email", "Email")
      .replaceAll("orderNumber", "Order Number")
      .replaceAll("items", "Items")
      .replaceAll("explainIssueQuestion", "Explain Issue Question")
      .replaceAll("streetAddress", "Street Address")
      .replaceAll("buildingAddress", "Building Address")
      .replaceAll("serialNumber", "Serial Number")
      .replaceAll("modelNumber", "Model Number")
      .replaceAll("dateOfManufacture", "Date Of Manufacture")
      .replaceAll("city", "City")
      .replaceAll("state", "State")
      .replaceAll("modelType", "Model Type")
      .replaceAll("firstName", "First Name")
      .replaceAll("lastName", "Last Name")
      .replaceAll("zipCode", "Zip Code")
      .replaceAll("phoneNumber", "Phone Number")
      .replaceAll("additionalInformation", "Additional Information")
      .replace('"', "")
      .replace(/{/g, "")
      .replace(/}/g, "")
      .replace(/,/g, "<br>");
  } else {
    parsedData = "";
  }
  return parsedData;
};

export const extractUserCode = () => {
  const user = JSON.parse(localStorage.getItem("userDetails"));
  return !!user && !!user.language ? user.language.code : null;
};

export const lazyLoadImage = (imageFolder, imageName) =>
  require(`assets/images/${imageFolder ? imageFolder + "/" : ""}${imageName}`);

export const getPositionClassName = (position) => {
  switch (position) {
    case "left":
      return "position-left";
    case "right":
      return "position-right";
    case "center":
      return "position-center";
    default:
      return ""; // Return an empty string for unknown positions
  }
};

export const getDirectionLayoutClassName = (direction) => {
  switch (direction) {
    case "row":
      return "row-layout";
    case "column":
      return "column-layout";
    default:
      return ""; // Return an empty string for unknown positions
  }
};

export const normalizeContent = (data, isArabic) => {
  const itemList = [];
  try {
    data?.forEach((item) => {
      if (item?.type === "footer_social") {
        const socialList = [];
        Object.keys(item?.content)?.forEach((key) => {
          socialList.push({ name: key, link: item.content[key] });
        });
        itemList[item.position - 1] = { isSocial: true, socialList };
      } else if (item.type === "footer_car_models") {
        itemList[item.position - 1] = {
          title: isArabic ? ar.translation.car : en.translation.car,
          children: (window.pageData && window.pageData.models) || [],
        };
      } else if (item?.type === "footer_section" && item.content) {
        const content = { title: item.content.Title?.[isArabic ? "ar" : "en"] };
        const entryList = [];
        let index = 0;
        while (item.content[`link${index}`] && item.content[`text${index}`]) {
          entryList.push({
            link: item.content[`link${index}`],
            text: item.content[`text${index}`][isArabic ? "ar" : "en"] || "",
          });
          index++;
        }
        // Object.keys(item.content).forEach((key) => {
        // if (key !== "Title") {
        // const index = key.replace(/[a-zA-Z]+/, "");
        // const value = item.content[key]?.[isArabic ? "ar" : "en"];
        // let indexValue = entryList[key.replace(/[a-zA-Z]+/, "")];
        // if (!indexValue) {
        //   indexValue = { [key.replace(/[0-9]+/, "")]: value };
        // } else {
        //   indexValue[key.replace(/[0-9]+/, "")] = value;
        // }
        // entryList[index] = indexValue;
        // }
        // });
        content.children = entryList;
        if (content.title || content.children.length) {
          itemList[item.position - 1] = content;
        }
      } else if (
        (item?.type === "header_link_custom" ||
          item?.type === "header_models") &&
        item.content
      ) {
        let label = item.content?.text || item.content?.models_item;
        label =
          typeof label === "string" ? label : label[isArabic ? "ar" : "en"];
        itemList[item.position - 1] = {
          link: item.content?.link,
          label: label,
          isModels: item?.type === "header_models",
        };
      }
    });
  } catch {}
  return itemList;
};
