import React from "react";
import useTranslation from "shared/hooks/useTranslation";
import useDeviceDetect from "shared/hooks/useDeviceDetect";
import placeholderImage from "assets/images/bydplaceholder.png";
const Image = ({ src, alt, ...rest }) => {
  const { platform } = useDeviceDetect();
  let newSrc = src;
  if (platform && src && typeof src === "object") {
    newSrc = src[platform];
  }
  const newAlt = useTranslation(alt);

  const handleError = (event) => {
    event.target.src = placeholderImage;
  };
  return (
    // <img src={placeholderImage} />
    <img
      src={newSrc?.replace("fm=jpg&", "")}
      onError={handleError}
      alt={newAlt}
      {...rest}
    />
  );
};

export default Image;
