import React from "react";
import classNames from "classnames";
import useTranslation from "shared/hooks/useTranslation";
import Image from "components/Image/Image";

import "./DataList.scss";

const DataList = ({ title, list, className }) => {
  return (
    <div className={classNames("c-data-list relative", className)}>
      {title && (
        <div className="c-title c-main-title mt-5">
          <h2>{useTranslation(title)}</h2>
        </div>
      )}

      <div className="c-data_container">
        {list?.map((item, index) => (
          <div
            key={index}
            className={classNames(
              "c-data row m-0",
              index % 2 === 1 && "flex-row-reverse"
            )}
          >
            {item.img && (
              <div className="c-image col-12 col-md-6 p-0">
                <Image src={item.img} alt={title} />
              </div>
            )}
            {(item.title || item.description) && (
              <div className="c-item col-12 col-md-6 p-0">
                {item.title && (
                  <h4 className="c-title px-0 px-md-5">
                    {useTranslation(item.title)}
                  </h4>
                )}
                {item.description && (
                  <div
                    className="c-description px-0 px-md-5"
                    dangerouslySetInnerHTML={{
                      __html: useTranslation(item.description),
                    }}
                  ></div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DataList;
