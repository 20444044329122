import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Switch, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import Loading from "components/Loading/Loading";
import PublicLayout from "layouts/PublicLayout/PublicLayout";

const loading = Loading();

const NotFound = React.lazy(() => import("./containers/NotFound/NotFound"));
const ComingSoon = React.lazy(() =>
  import("./containers/ComingSoon/ComingSoon")
);

const Home = React.lazy(() => import("./containers/Home/Home"));
const ContactUs = React.lazy(() => import("./containers/ContactUs/ContactUs"));
const About = React.lazy(() => import("./containers/About/About"));
const Car = React.lazy(() => import("./containers/Car/Car"));
const MaintenanceService = React.lazy(() =>
  import("./containers/MaintenanceService/MaintenanceService")
);
const NewsList = React.lazy(() => import("./containers/NewsList/NewsList"));
const NewsDetails = React.lazy(() =>
  import("./containers/NewsDetails/NewsDetails")
);
import "./App.scss";
import { useQueryTwillPages } from "shared/services/queries";
import CustomPage from "containers/CustomPage/CustomPage";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const App = () => {
  const { data: pages } = useQueryTwillPages();

  const lightTheme = createTheme({
    palette: {
      type: "light",
      primary: {
        light: "#f2f8f8",
        main: "rgba(37, 39, 40, 0.8)",
        dark: "rgba(37, 39, 40, 0.8)",
        contrastText: "#fff",
      },
      secondary: {
        light: "#fff",
        main: "#fff",
        dark: "#fff",
        contrastText: "#32c1c1",
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={loading}>
        <ThemeProvider theme={lightTheme}>
          <BrowserRouter>
            <Switch>
              <PublicLayout exact path="/:lang" component={Home} />
              <PublicLayout exact path="/:lang/car/:id" component={Car} />
              <PublicLayout exact path="/:lang/about-byd" component={About} />
              <PublicLayout
                exact
                path="/:lang/contact-us"
                component={ContactUs}
              />
              <PublicLayout
                exact
                path="/:lang/coming-soon"
                component={ComingSoon}
              />
              <PublicLayout
                exact
                path="/:lang/service-maintenance"
                component={MaintenanceService}
              />
              <PublicLayout
                exact
                path="/:lang/news-list"
                component={NewsList}
              />
              <PublicLayout
                exact
                path="/:lang/news-list/:id"
                component={NewsDetails}
              />
              {/* custom page */}
              {pages?.map((page, index) => {
                return (
                  <PublicLayout
                    exact
                    key={page.slug}
                    path={"/:lang/" + page.slug}
                    component={CustomPage}
                  />
                );
              })}
              <Redirect exact path="/" to="/en-ps" />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </Suspense>
    </QueryClientProvider>
  );
};

export default App;
