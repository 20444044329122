import React from "react";
import { ReactComponent as Twitter } from "assets/images/twitter.svg";
import { ReactComponent as Facebook } from "assets/images/facebook.svg";
import { ReactComponent as Instagram } from "assets/images/instagram.svg";
import { ReactComponent as Tiktok } from "assets/images/tiktok.svg";
import { ReactComponent as Youtube } from "assets/images/youtube.svg";
import { ReactComponent as Whatsapp } from "assets/images/whatsapp.svg";
import { useTranslation as useLocale } from "react-i18next";

const SOCIAL_LINK = {
  facebook: {
    label: "facebook",
    img: <Facebook className="social-icons" />,
    link: "https://www.facebook.com/bydautopalestine?mibextid=LQQJ4d",
  },
  instagram: {
    label: "instagram",
    img: <Instagram className="social-icons" />,
    link: "https://www.instagram.com/bydautopalestine?igsh=dmJldTFyZGliaWk1",
  },
  tiktok: {
    label: "tiktok",
    img: <Tiktok className="social-icons" />,
    link: "https://www.tiktok.com/@bydautopalestine?_t=8lnPMA2iUXS&_r=1",
  },
  youtube: {
    label: "youtube",
    img: <Youtube className="social-icons" />,
    link: "https://youtube.com/@bydautopalestine?si=_U8dD4naoYMx8LTy",
  },
  whatsapp: {
    label: "whatsapp",
    img: <Whatsapp className="social-icons" />,
    link: "https://wa.me/+970593314000",
  },
  twitter: {
    label: "twitter",
    img: <Twitter className="social-icons" />,
    link: "https://twitter.com/intent/tweet?text=",
  },
};

const SocialLink = ({ data, share }) => {
  const { t } = useLocale();
  let links = data;
  if (!data) {
    links = SOCIAL_LINK;
  }
  console.log(links);
  return (
    <div className="d-flex flex-column text-center w-100">
      {!share && <span className="w-100">{t("follow-us")}</span>}
      <ul className="align-items-center d-flex justify-content-center p-0">
        {links &&
          links.length &&
          links.length > 0 &&
          links.map(({ name, link }) => (
            <li key={name}>
              <a
                href={link}
                target="_blank"
                className="d-block"
                rel="noreferrer"
                alt={name}
              >
                {SOCIAL_LINK[name].img}
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SocialLink;
