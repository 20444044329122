import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, Collapse, Link } from "@mui/material";
import ScrollAnimation from "react-animate-on-scroll";
import DataList from "components/DataList/DataList";
import Form from "components/Form/Form";
import { Add as AddIcon } from "@mui/icons-material";
import classNames from "classnames";
import useTranslation from "shared/hooks/useTranslation";
import Image from "components/Image/Image";
import { useTranslation as useLocale } from "react-i18next";

import "./CoverImg.scss";
import { getPositionClassName } from "shared/helpers/utils";

const CoverImg = ({
  img,
  scrollIndex,
  title,
  description,
  link,
  href,
  linkTitle,
  list,
  className,
  index,
  formList,
  date,
  color,
  position = "center",
}) => {
  const [checked, setChecked] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isAboutPage, setIsAboutPage] = useState(false);
  const positionClass = getPositionClassName(position);
  const location = useLocation();
  const { t } = useLocale();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991); // Adjust the breakpoint as needed
    };

    const checkAboutPage = () => {
      setIsAboutPage(location.pathname.indexOf("about-byd") > -1);
    };

    handleResize(); // Call on initial render
    checkAboutPage();
    window.addEventListener("resize", handleResize); // Add event listener for window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup
    };
  }, [location]);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <div
      className={classNames(
        "c-cover-image",
        list?.length && "c-cover-image_with-list"
      )}
    >
      {(useTranslation(title) || useTranslation(description)) && (
        <ScrollAnimation
          className={
            useTranslation(title) &&
            (!useTranslation(description) ||
              (useTranslation(description) &&
                !useTranslation(description).trim().length))
              ? ""
              : "c-push-position"
          }
          animateIn="fadeInUp"
          delay={10}
          duration={0.7}
          initiallyVisible={index < (scrollIndex || 1) ? true : false}
          animateOnce={true}
        >
          <div className={classNames("c-title", className, positionClass)}>
            <h2
              style={{
                color: isMobile && isAboutPage ? "#000" : color || "inherit",
              }}
            >
              {useTranslation(title)}
            </h2>
            {date && (
              <span
                style={{
                  color: isMobile && isAboutPage ? "#000" : color || "inherit",
                }}
                className="c-date"
              >
                {date}
              </span>
            )}
            {description && (
              <p
                style={{
                  color: isMobile && isAboutPage ? "#000" : color || "inherit",
                }}
                className={classNames("c-description", positionClass)}
                dangerouslySetInnerHTML={{
                  __html: useTranslation(description),
                }}
              />
            )}
          </div>
        </ScrollAnimation>
      )}
      {img && (
        <div className="c-image">
          <Image src={img} alt={title} />
        </div>
      )}
      {useTranslation(linkTitle) && (
        <div className="c-link">
          <Link href={link}>{useTranslation(linkTitle)}</Link>
        </div>
      )}
      {(list?.length || formList) && (
        <div>
          <div
            className={classNames(
              "c-cover-image__plus_container",
              checked && "open"
            )}
          >
            <Button
              className="c-cover-image__plus_container__btn"
              onClick={handleChange}
            >
              <AddIcon />
            </Button>
            <div className="mt-2 learn-more">{t("learnMore")}</div>
          </div>

          <Collapse in={checked}>
            {list ? <DataList list={list}></DataList> : <></>}
            {formList ? (
              <Form
                form={formList.list}
                title={formList.title}
                img={formList.img}
                buttonText={formList.buttonText}
              ></Form>
            ) : (
              <></>
            )}
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default CoverImg;
