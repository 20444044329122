import React from "react";
import classNames from "classnames";
import useTranslation from "shared/hooks/useTranslation";
import {
  TextareaAutosize,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useForm, Controller } from "react-hook-form";
import Image from "components/Image/Image";
import FormControlLabel from "@mui/material/FormControlLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import "./Form.scss";

const Form = ({
  formList,
  title,
  description,
  img,
  buttonText,
  sendRequest,
  schemaObj,
}) => {
  let schema = schemaObj ? yup.object(schemaObj).required() : yup.object({});
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const renderInput = (item) => {
    switch (item.type) {
      case "text":
        return (
          <Controller
            name={item.fieldName}
            control={control}
            // rules={{required: true}}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id={item.fieldName}
                label={item.fieldLabel}
                type="text"
                color="secondary"
                variant="standard"
              />
            )}
          />
        );
      case "checkbox":
        return (
          <FormControlLabel
            label={item.fieldLabel}
            {...(item.fieldName !== "newsletters" && { required: true })}
            control={
              <Controller
                name={item.fieldName}
                id={item.fieldName}
                label={item.fieldLabel}
                control={control}
                render={({ field }) => <Checkbox {...field} />}
              />
            }
          />
        );
      case "textArea":
        return (
          <Controller
            name={item.fieldName}
            control={control}
            // rules={{required: true}}
            render={({ field }) => (
              <TextareaAutosize
                {...field}
                fullWidth
                minRows={6}
                id={item.fieldName}
                label={item.fieldLabel}
                type="text"
                color="secondary"
                variant="standard"
              />
            )}
          />
        );
      case "email":
        return (
          <Controller
            name={item.fieldName}
            control={control}
            // rules={{required: true}}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id={item.fieldName}
                label={item.fieldLabel}
                color="secondary"
                type="email"
                variant="standard"
              />
            )}
          />
        );
      case "calender":
        return (
          <Controller
            control={control}
            name="date"
            // rules={{required: true}}
            render={({ field }) => {
              return (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Preferred Date"
                    value={field.value}
                    // inputRef={field.ref}
                    color="secondary"
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                  />
                </LocalizationProvider>
              );
            }}
          />
        );
      case "dropdown":
        return (
          <Controller
            name={item.fieldName}
            control={control}
            // rules={{required: true}}
            render={({ field }) => (
              <>
                <InputLabel id="demo-simple-select-standard-label">
                  {item.fieldLabel}
                </InputLabel>
                <Select
                  {...field}
                  // required
                  fullWidth
                  labelId={item.fieldLabel}
                  id={item.fieldName}
                  label={item.fieldLabel}
                  color="secondary"
                  variant="standard"
                >
                  {item.menuItems.map((menuItem) => {
                    return (
                      <MenuItem key={menuItem.value} value={menuItem.value}>
                        {menuItem.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            )}
          />
        );
      default:
        return;
    }
  };

  return (
    <div className={classNames("c-form-container")}>
      {img && (
        <div className="c-image">
          <Image src={img} alt={title} />
        </div>
      )}
      <div className="c-form">
        {title && (
          <div className="c-title">
            <h6
              dangerouslySetInnerHTML={{ __html: useTranslation(title) }}
            ></h6>
          </div>
        )}
        {description && (
          <div className="c-description">
            <div
              dangerouslySetInnerHTML={{ __html: useTranslation(description) }}
            ></div>
          </div>
        )}

        <div className="c-form">
          <form
            onSubmit={handleSubmit((data) => {
              sendRequest && sendRequest(data);
            })}
          >
            {formList?.map((item, index) => (
              <div className={item.fieldName} key={index}>
                {renderInput(item)}
              </div>
            ))}
            <span className="c-error">
              {Object.keys(errors).length
                ? "Please fill all the required fields and accept the terms."
                : ""}
            </span>
            <Button
              color="secondary"
              variant="outlined"
              id="submit"
              type="submit"
            >
              {buttonText}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;

// export default function Form() {
//     const { register, handleSubmit } = useForm()
//     const onSubmit = (data) => {
//
//         console.log('data')
//         console.log(data)}
//
//     return (
//         <form onSubmit={handleSubmit(onSubmit)}>
//             <Input id='firstName' name='firstName' {...register("firstName")}  />
//             <select id='gender' {...register("gender")}>
//                 <option key='1' value="female">female</option>
//                 <option key='3' value="male">male</option>
//                 <option key='2' value="other">other</option>
//             </select>
//             <Input id='submit' name='submit' type="submit" />
//         </form>
//     )
// }
