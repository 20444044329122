import React from "react";
import "./Models.scss";
import Image from "components/Image/Image";
import useTranslation from "shared/hooks/useTranslation";
import { useHistory } from "react-router-dom";

export default function Models({ list, closeMenu }) {
  return (
    <ul className="c-models">
      {list.map((model, index) => {
        return (
          <li key={index}>
            <a href={model.link} title={model.tabTitle} class="car-item show">
              <Image src={model.img} alt={model.tabTitle} />
              <strong className="title">{model.tabTitle}</strong>
            </a>
          </li>
        );
      })}
    </ul>
  );
}
