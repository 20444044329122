import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from "./translations/en";
import { ar } from "./translations/ar";

const resources = {
  en: en,
  ar: ar,
};
i18n.use(initReactI18next).init({
  resources,
  lng: window.location.pathname.split("/")[1].split("-")[0],

  interpolation: {
    escapeValue: false,
  },
});
