import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "@mui/material";
import useTranslation from "shared/hooks/useTranslation";
import Image from "components/Image/Image";

import "./LinkImg.scss";
import classNames from "classnames";

const LinkImg = ({
  img,
  title,
  link,
  className,
  titleClassName,
  description,
  initiallyVisible,
  date,
}) => {
  return (
    <Link href={link} className={classNames("c-nav_img relative", className)}>
      {/*<Link href={link} className="c-nav_img relative">*/}
      <div className="c-image">
        <Image src={img} alt={title} />
      </div>
      <ScrollAnimation
        animateIn="fadeInUp"
        delay={10}
        duration={0.7}
        initiallyVisible={initiallyVisible || false}
        animateOnce={true}
      >
        <div className={`c-title ${titleClassName}`}>
          <h2>{useTranslation(title)}</h2>
          {description && (
            <p className="c-description">{useTranslation(description)}</p>
          )}
          {date && <p className="c-date"> {date}</p>}
        </div>
      </ScrollAnimation>
    </Link>
  );
};

export default LinkImg;
