import React from "react";
import CustomReactPlayer from "components/CustomReactPlayer/CustomReactPlayer";

import "./Video.scss";

const Video = ({ link, video }) => {
  return (
    <div className="c-video">
      {(link || video) && (
        <CustomReactPlayer
          url={video || link}
          playing
          loop
          controls={false}
          playsinline={true}
          muted
          width="100%"
          height="100%"
          className="d-flex"
        />
      )}
    </div>
  );
};

export default Video;
