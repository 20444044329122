import React from "react";
import PropTypes from "prop-types";
import "./Text.scss";
import useTranslation from "shared/hooks/useTranslation";

const TextWidget = ({ title, text }) => {
  return (
    <div className="text-widget">
      {title && <h2>{useTranslation(title)}</h2>}
      {text && <p>{useTranslation(text)}</p>}
    </div>
  );
};

TextWidget.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default TextWidget;
